import React from "react"
import About from "../components/homedefault/about"
import Chooseus from "../components/homedefault/chooseus"
import Layout from "../components/layout"
import Testimonial from "../components/homedefault/testimonial";

export default function AboutPage() {
  return (
    <Layout>
      <About />
      <Chooseus />
      {/* TODO: Start here  */}
      {/* <Testimonial /> */}
    </Layout>
  )
}
