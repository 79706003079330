import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from "gatsby-image";

const Chooseus = () => {
    const chooseusQueryData = useStaticQuery(graphql`
        query chooseusQueryQuery {
            homedefaultJson(id: {eq: "chooseus"}) {
                imagegroup {
                    image {
                        childImageSharp {
                            fluid(maxHeight: 980, maxWidth: 950, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                                presentationHeight
                                presentationWidth
                            }
                        }
                    }
                }
            }
        }
    `)
    const ChooseusImage = chooseusQueryData.homedefaultJson.imagegroup;

    return (
        <div className="rn-choose-us-area choose-us-style-01 rn-section-gapBottom">
            <div className="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="section-title">
                                    <h3 className="title mb--100 mb_lg--50 mb_md--50 mb_sm--40"></h3>
                                </div>
                                <div className="feature-list-inner">
                                    <div className="single-feature">
                                        <h4 className="title">Technical Leadership</h4>
                                        <ul className="feature-list">
                                            <li>Architecture</li>
                                            <li>Strategy</li>
                                            <li>Security</li>
                                            <li>Team Alignment</li>
                                            <li>Neurodiversity and Tech Specialist</li>
                                        </ul>
                                    </div>
                                    <div className="single-feature">
                                        <h4 className="title">Development</h4>
                                        <ul className="feature-list">
                                            <li>Design System Engineer</li>
                                            <li>App Development</li>
                                            <li>Full-stack Engineer</li>
                                            <li>UI Architect</li>
                                            <li>Javascript, React, Ruby/Rails, Node</li>
                                        </ul>
                                    </div>
                                    <div className="single-feature">
                                        <h4 className="title">Coaching</h4>
                                        <ul className="feature-list">
                                            <li>Goal Setting</li>
                                            <li>Accountability</li>
                                            <li>Career Development</li>
                                            <li>Tutoring</li>
                                        </ul>
                                    </div>
                                    <div className="single-feature">
                                        <h4 className="title">Speaking/Teaching</h4>
                                        <ul className="feature-list">
                                            <li>Technical Leadership</li>
                                            <li>Live Coding Sessions</li>
                                            <li>Empowerment</li>
                                            <li>Emotional Intelligence</li>
                                        </ul>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>    
            </div> 
            <div className="image-group">
                {ChooseusImage.map((data, index) => (
                    <div className="single-image" key={index}>
                        <Img fluid={data.image.childImageSharp.fluid} />
                    </div>
                ))}
            </div>   
        </div>
    )
}
export default Chooseus;
